const baseUrl = 'bluerain';

export const ApiPaths = {
    Login: `${baseUrl}/sheng`,
    Logout: `${baseUrl}/gong`,
    CustomerCustomToken: `${baseUrl}/shengma`,
    UpdatePassword: `${baseUrl}/goi`,
    CustomerForgotPassword: `${baseUrl}/customerforgotpassword`,
    SkipTourHome: `${baseUrl}/skiptourhome`,

    UserProfiles: `${baseUrl}/userprofiles`,

    SystemInfo: `${baseUrl}/systeminfo`,
    DbInfo: `${baseUrl}/dbinfo`,
    AppSetting: `${baseUrl}/appsetting`,

    // fpx
    FpxBanks: `${baseUrl}/fpx/fpxbanks`,
    FpxConfirm: `${baseUrl}/fpx/fpxconfirm`,

    // fpx testing
    FpxUatConfirm: `${baseUrl}/fpx/fpxuatconfirm`,
    FpxUatRequery: `${baseUrl}/fpx/fpxuatrequery`,
    FpxProdConfirm: `${baseUrl}/fpx/fpxprodconfirm`,
    FpxProdRequery: `${baseUrl}/fpx/fpxprodrequery`,

    PaymentMethods: `${baseUrl}/paymentmethods`,

    Pawns: `${baseUrl}/pawns`,
    AllDueInterests: `${baseUrl}/alldueinterests`,

    ExtendGroups: `${baseUrl}/extendgroups`,
    Extends: `${baseUrl}/extends`,

    RedeemUncollectGroups: `${baseUrl}/redeemuncollectgroups`,
    RedeemUncollects: `${baseUrl}/redeemuncollects`,

    CashPointBal: `${baseUrl}/cashpointbal`,
    CashPointBalSummary: `${baseUrl}/cashpointbalsummary`,
    CashPointPromos: `${baseUrl}/cashpointpromos`,
    CashPointClaims: `${baseUrl}/cashpointclaims`,

};